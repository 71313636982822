import { useState, useEffect, FC } from 'react';
import { Group, Title } from '@mantine/core';
import classes from "./../Theme.module.css";

// Define a type for the props
interface StopwatchProps {
  initialCount?: number;
  startImmediately?: boolean;
  onEmitUpdatedCount: (count: number) => void; // Assuming onEmitUpdatedCount is a function that takes a number
}

export const Stopwatch: FC<StopwatchProps> = ({ initialCount = 0, startImmediately = false, onEmitUpdatedCount }) => {
  const [time, setTime] = useState("00:00:00");
  const [start, setStart] = useState(startImmediately);
  const [startTime, setStartTime] = useState<number | null>(null);

  useEffect(() => {
    // This will update the timer display with the initialCount value
    // whenever the initialCount changes, including on component mount.
    showTimer(initialCount);
  }, [initialCount]);

  useEffect(() => {
    setStart(startImmediately);
  }, [startImmediately]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
  
    if (start) {
      const startTimestamp = startTime || Date.now() - initialCount;
      setStartTime(startTimestamp);
  
      intervalId = setInterval(() => {
        const elapsed = Date.now() - startTimestamp;
        showTimer(elapsed);
        // console.log("emitting updated count", elapsed);
        onEmitUpdatedCount(elapsed);
      }, 1000); // Update every second for better performance
    } else {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    }
  
    return () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    };
  }, [start, startTime, initialCount, onEmitUpdatedCount]);

  const showTimer = (ms: number) => {
    const second = Math.floor((ms / 1000) % 60).toString().padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60) % 60).toString().padStart(2, "0");
    const hour = Math.floor(ms / 1000 / 60 / 60).toString().padStart(2, "0");
    setTime(`${hour}:${minute}:${second}`);
  };

  return (
    
      <Group className={classes.Stopwatch} justify="center">
        <Title className={`${classes.Stopwatch} ${classes.display}`} order={2}>Elapsed Time:</Title>
        <Title className={`${classes.Stopwatch} ${classes.display}`} order={2}>{time}</Title>
      </Group>
  );
}

export default Stopwatch;