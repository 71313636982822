


/**
 * Perform a substitution cipher on a string using a given mapping.
 *
 * @param {String} text
 * @param {Record<string, string>} mapping
 * @return {*}  {string}
 */
function performSubstitutionCipher(text: string, mapping: Record<string, string>): string {
    return text.split('').map(char => {
        const upperChar = char.toUpperCase();
        if (mapping[upperChar]) {
            // Preserve the case of the original character
            return char === upperChar ? mapping[upperChar] : mapping[upperChar].toUpperCase();
        } else {
            return char;
        }
    }).join('');
}


/**
 * Options for the cipher.
 *
 * @interface CipherOptions
 */
interface CipherOptions {
    noSelfMapping?: boolean;
    alphabet?: string;
  }


/**
 * Generate a string of the alphabet containing only the characters present in the input message.
 *
 * @param {string} inputMessage
 * @return {*}  {string}
 */
function generateMinimalAlphabet(inputMessage: string): string {
    inputMessage = inputMessage.toUpperCase();
    const standardAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    // Filter out characters that are not in the alphabet
    inputMessage = inputMessage.split('').filter(char => standardAlphabet.includes(char)).join('');
    inputMessage = [...new Set(inputMessage)].sort().join('');
    return inputMessage
}

type Mapping = { [key: string]: string };

class UserSolution {
    private mapping: Mapping;
    private readonly unusedCharMarker: string = '*';
    private readonly standardAlphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    [key: string]: any; // Index signature allowing string keys and any values
    constructor() {
        this.mapping = {};
        // Initialize the mapping with the standard alphabet, marked as unused
        for (const char of this.standardAlphabet) {
            this.mapping[char] = this.unusedCharMarker;
        }
    }

    // Clone the current instance
    clone(): UserSolution {
        const clone = new UserSolution();
        // clone.standardAlphabet = [...this.standardAlphabet];
        clone.mapping = { ...this.mapping };
        // Clone other properties if there are any
        return clone;
    }

    
    /**
     * Set the mapping of the UserSolution instance.
     *
     * @param {Mapping} newMapping
     * @return {*}  {UserSolution}
     * @memberof UserSolution
     */
    setMapping(newMapping: Mapping): UserSolution {
        const clone = this.clone(); // Clone the current instance
        const validChars = new Set(this.standardAlphabet);

        // Validate the new mapping
        for (const [key, value] of Object.entries(newMapping)) {
            if (!validChars.has(key) || (!validChars.has(value) && value !== this.unusedCharMarker)) {
                console.error(`Invalid mapping: ${key} -> ${value}`);
                return this; // Return the original instance if validation fails
            }
        }

        // Update the mapping of the cloned instance
        clone.mapping = { ...newMapping };
        return clone; // Return the modified clone
    }

    // Adjusted updateMapping method
    updateMapping(chipherChar: string, plainChar: string): UserSolution {
        const clone = this.clone(); // Clone the current instance
        if (clone.standardAlphabet.includes(chipherChar)) {
            clone.mapping[chipherChar] = plainChar;
        } else {
            console.error(`${chipherChar} is not a valid character in the standard alphabet.`);
        }
        return clone; // Return the modified clone
    }

    // * TBC: the getter method needs to swap the keys for values, then sort the resulting keys, and return the result.
    // Getter method for the mapping
    // getMapping(): Mapping {
    //     const swappedMapping: Mapping = {};
    //     for (const key in this.mapping) {
    //         if (this.mapping.hasOwnProperty(key)) {
    //             const value = this.mapping[key];
    //             swappedMapping[value] = key;
    //         }
    //     }
    //     const sortedKeys = Object.keys(swappedMapping).sort();
    //     const result: Mapping = {};
    //     for (const key of sortedKeys) {
    //         result[key] = swappedMapping[key];
    //     }
    //     return result;
    // }


    getMapping(): Mapping {
        const tempMapping: Mapping = {};
        // Initialize the mapping with the standard alphabet, marked as unused
        for (const char of this.standardAlphabet) {
            tempMapping[char] = this.unusedCharMarker;
        }
        // Step 2: Iterate over this.mapping
        Object.entries(this.mapping).forEach(([key, value]) => {
            // Step 3: Check if value exists as a key in tempMapping
            if (value in tempMapping) {
                tempMapping[value] = key;
            } else {
                // TODO: Handle invalid mappings or remove this else block.
            }
        });
    
        // Step 4: Sort the keys of tempMapping
        const sortedKeys = Object.keys(tempMapping).sort();
    
        // Step 5: Create a new Mapping object for sorted results
        const result: Mapping = {};
    
        // Step 6: Populate the new Mapping object with sorted keys and values
        sortedKeys.forEach(key => {
            result[key] = tempMapping[key];
        });
    
        // Step 7: Return the new Mapping object
        return result;
    }

    getMapping2(): UserSolution {
        const retrievedSolution = new UserSolution();
    
        Object.entries(this.mapping).forEach(([key, value]) => {
            if (value in retrievedSolution.mapping) {
                retrievedSolution.mapping[value] = this.mapping[key];
            }
        });
    
        // Assuming updateMapping is a method that needs to be called
        // retrievedSolution.updateMapping();
    
        return retrievedSolution;
    }

    getPlainMapping(): Mapping {
        const plainMapping: Mapping = {};
        for (const [key, value] of Object.entries(this.mapping)) {
            if (value !== this.unusedCharMarker) {
                plainMapping[key] = value;
            }
        }
        return plainMapping;
    }
}

/**
 * Generate an object representing the usage of each character in the alphabet in the input message.
 *
 * @param {string} inputMessage
 * @return {*}  {Mapping}
 */
function userSolutionMap(inputMessage: string): Mapping {
    console.log('inputMessage:', inputMessage);
    const result = new UserSolution();
    // Now we update the result with character mappings
    

    return result.getMapping();
}



/**
 * Generate a random substitution cipher mapping.
 *
 * @return {*}  {Record<string, string>}
 */
function generateSubstitutionCipherMapping(CipherOptions: CipherOptions = {}): Record<string, string> {
    // If no alphabet is provided, use the standard English alphabet. If an alphabet is provided, use that.
    const alphabet = CipherOptions.alphabet ? CipherOptions.alphabet.split('') : 'abcdefghijklmnopqrstuvwxyz'.split('');


    const shuffledAlphabet = [...alphabet].sort(() => Math.random() - 0.5);
    const mapping: Record<string, string> = {};

    if (CipherOptions.noSelfMapping) {
        for (let i = 0; i < alphabet.length; i++) {
          if (alphabet[i] === shuffledAlphabet[i]) {
            // Find a different character to swap with
            const swapIndex = (i + 1 + Math.floor(Math.random() * (alphabet.length - 1))) % alphabet.length;
            [shuffledAlphabet[i], shuffledAlphabet[swapIndex]] = [shuffledAlphabet[swapIndex], shuffledAlphabet[i]];
          }
        }
      }

    for (let i = 0; i < alphabet.length; i++) {
        mapping[alphabet[i]] = shuffledAlphabet[i];
    }

    return mapping;
}


/**
 * Convert a cipher mapping to a human-readable string.
 *
 * @param {Record<string, string>} cipherMapping
 * @return {*}  {string}
 */
function mappingString(cipherMapping: Record<string, string>): string {
    return Object.entries(cipherMapping)
        .map(([key, value]) => `${key} -> ${value}`)
        .join(', ');
}

/**
 * Compute the SHA-256 hash of a string or an object.
 *
 * @param {(string | Record<string, string>)} data
 * @return {*}  {Promise<string>}
 */
async function computeMapHash(data: string | Record<string, string>): Promise<string> {
    let dataString: string;

    if (typeof data === 'string') {
        dataString = data;
    } else {
        dataString = Object.values(data).join('');
    }

    const hash = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(dataString));
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export { generateSubstitutionCipherMapping, performSubstitutionCipher, computeMapHash, mappingString, generateMinimalAlphabet, userSolutionMap, UserSolution };