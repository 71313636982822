import { Button, Card, Center, Flex, Group, Modal, Space, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { createRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GameParams } from '../types/gameTypes';
import { computeMapHash, performSubstitutionCipher, UserSolution } from '../utils/cipher';
import { notifications } from '@mantine/notifications';

import React from 'react';
import Sentence from './Sentence';

type GameProps = {
  // Define the type of props here
  // For example:
  gameParams: GameParams;
  onFirstInteraction: () => void;
  onSolve: (plaintext: string) => void;
  onDebugButton: () => void;
  onUserSolutionUpdate: (userEntries: UserSolution) => void;
  plainTextSolution?: string | null;
  initialUserSolution?: UserSolution | undefined;
};

export function Game({ gameParams: initialGameParams, onFirstInteraction, onSolve, onUserSolutionUpdate, plainTextSolution, initialUserSolution }: GameProps) {
  // Glossary of terms:
  // - navigate: The navigate function from react-router-dom
  // - location: The location object from react-router-dom
  // - gameParams: The game parameters

  // - cipherText: The text to be solved
  // - solutionHash: The hash of the solution

  // - letterCounts: A dictionary of letter frequencies in the cipher text
  // - cipherWords: An array of words in the cipher text
  // - letters: An array of individual letters in the cipher text
  // - letterrefs: An array of refs to the Letter components
  // - selectedLetter: The currently selected letter
  // - selectedInputIndex: The index of the currently selected input
  // - userEntries: The user's mapping solution
  // - currentUserSolution: The current user solution

  // - hasStarted: A boolean indicating if the user has started interacting with the game
  // - modalOpened: A boolean indicating if the modal is opened

  // **************** Setup Variables ****************
  const navigate = useNavigate();

  const defaultCipherText = '';
  const defaultSolutionHash = '';

  const cipherText = initialGameParams.cipherText ?? defaultCipherText;
  const solutionHash = initialGameParams.solutionHash ?? defaultSolutionHash;
  const hintMapping = initialGameParams.hintMapping ?? '';
  
  const letterCounts = getLetterCounts(cipherText);
  const cipherWords = cipherText.split(' '); // Break the cipher text into words
  // Create a Letter component for each letter in the word
  const letters = cipherText.split('').filter((letter) => letter !== ' '); 

  // **************** Game State ****************
  const [hasStarted, setHasStarted] = useState(false);
  const [modalOpened, { open: openPuzzleSolvedModal, close: closePuzzleSolvedModal }] = useDisclosure(false);
  const [modalIncorrectOpened, { open: openIncorrectModal, close: closeIncorrectModal }] = useDisclosure(false);

  const letterRefs = useRef<(React.RefObject<HTMLInputElement>)[]>(letters.map(() => createRef()));
  // Initialize selectedLetter and selectedInputIndex from local storage or default to an empty string and -1
  const [selectedLetter, setSelectedLetter] = useState(() => localStorage.getItem('selectedLetter') || '');
  const [selectedInputIndex, setSelectedInputIndex] = useState(() => {
    const savedIndex = localStorage.getItem('selectedInputIndex');
    return savedIndex ? parseInt(savedIndex, 10) : -1;
  });

  function loadUserEntries(initialUserEntries:UserSolution): UserSolution {
    let temp = new UserSolution();
    const tempMapping = initialUserEntries['mapping'];

    temp = temp.setMapping(tempMapping);

    // Iterate through each key and update the temp mapping
    // for (const [key, value] of Object.entries(tempMapping)) {
    //   temp.updateMapping(key, value);
    // }
    // Load user entries from local storage
    return temp;
  }

  const initializeUserEntries = () => {
    if (initialUserSolution) {
      return loadUserEntries(initialUserSolution);
    }
    return new UserSolution();
  };
  
  const [userEntries, setUserEntries] = useState<UserSolution>(initializeUserEntries);
  
  // const [userEntries, setUserEntries] = useState<UserSolution>(new UserSolution());


  // **************** Effect Hooks ****************
  // Focus the input box with the index selectedInputIndex after the component mounts
  useEffect(() => {

    if (selectedInputIndex >= 0 && selectedInputIndex < letterRefs.current.length) {
      console.log('Focusing input with index', selectedInputIndex);
      const inputToFocus = letterRefs.current[selectedInputIndex];
      if (inputToFocus?.current) {
        console.log('Focusing input');
        inputToFocus.current.focus();
      }
    }
  }, []); // Empty dependency array means this effect runs once after the initial render

  // Save selectedLetter and selectedInputIndex to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('selectedLetter', selectedLetter);
    localStorage.setItem('selectedInputIndex', selectedInputIndex.toString());
  }, [selectedLetter, selectedInputIndex]);

  useEffect(() => {
    console.debug('useEffect ran: userEntries')
    onUserSolutionUpdate(userEntries);
  }, [userEntries])

  useEffect(() => {
    const duplicates = checkForDuplicates(userEntries);

    // Update the sentence whenever userEntries, selectedLetter, or selectedInputIndex changes
    console.debug('useEffect ran: userEntries, selectedLetter, selectedInputIndex');
    setSentence(<Sentence cipherWords={cipherWords} letterRefs={letterRefs} letterCounts={letterCounts} selectedLetter={selectedLetter} userEntries={userEntries} focusNextInput={focusNextInput} handleUserInput={handleUserInput} handleKeyEvent={handleKeyEvent} handleFocusEvent={handleFocusEvent} duplicates={duplicates} />);
  }, [userEntries, selectedLetter, selectedInputIndex]);

  // **************** Game Control Functions ****************
  const focusNextInput = (currentInputIndex: number, offset = 1, direction = 1) => {
    let nextIndex = currentInputIndex + (offset * direction);
    // Adjust loop condition for both directions
    while ((direction === 1 && nextIndex < letters.length) || (direction === -1 && nextIndex >= 0)) {
      const nextInput = letterRefs.current[nextIndex];
      // Check if the next character is alphabetic; if not, or if nextInput.current is null, skip to the next/previous
      if (!letters[nextIndex].match(/[a-zA-Z]/) || !nextInput?.current) {
        nextIndex += direction; // Move to the next/previous index based on direction
        continue;
      }
      nextInput.current.focus();
      if (nextInput.current.value) {
        nextInput.current.select();
      }
      break; // Break the loop once a valid next input is focused
    }
  };

  // **************** Event Handlers ****************
  function handleUserInput(letterKey: string, enteredLetter: string) {
    setUserEntries((currentEntries) => currentEntries.updateMapping(letterKey, enteredLetter));

    // Let parent function know that interaction has begun
    if (!hasStarted) {
      onFirstInteraction(); // Notify the parent component
      setHasStarted(true); // Ensure it's only called once
    }
  }

  const handleKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle keydown event logic here
    console.log(`Keydown detected on input with key '${event.key}'`);
    switch ((event as React.KeyboardEvent<HTMLInputElement>).key) {
      case 'Backspace':
        // Your existing logic for handling backspace
        console.log('Backspace was pressed');
        if (!event.currentTarget.value) {
          // Assuming focusPreviousInput is correctly implemented to focus the previous input
          focusNextInput(selectedInputIndex - 2);
          //  NOTE: Optionally, uncomment the following line to change the beahiour of the backspace key from clearing the previous input to focusing the previous input. I.e., uncommenting the following line will make the backspace equivalent to the left arrow key.
          // event.preventDefault(); // Prevent the default backspace action if necessary
        }
        break;
      case 'ArrowLeft':
        // Logic to focus the previous input
        focusNextInput(selectedInputIndex - 2);
        event.preventDefault(); // Prevent the default arrow action to ensure custom behavior
        break;
      case 'ArrowRight':
        // Logic to focus the next input
        focusNextInput(selectedInputIndex);
        event.preventDefault(); // Prevent the default arrow action to ensure custom behavior
        break;
      default:
        // Handle other keys if necessary
        break;
    }
  };


  const handleFocusEvent = (currentAbsoluteIndex: number, letterKey: string) => {
    setSelectedLetter(letterKey);
    setSelectedInputIndex(currentAbsoluteIndex); // Update the focused input index
    (letterRefs.current[currentAbsoluteIndex] as React.MutableRefObject<HTMLInputElement | null>)?.current?.select();
  };

  const handleSubmit = async () => {
    const temp = userEntries.getMapping();
    const isCorrect = await checkSolution(solutionHash, temp);
    if (isCorrect) {
      openPuzzleSolvedModal();
      const plaintext = performSubstitutionCipher(cipherText, userEntries.getPlainMapping());
      onSolve(plaintext); // Notify the parent component of the solution
    } else {
      openIncorrectModal();
    }
  };

  const handleReset = () => {
    setUserEntries(new UserSolution); // Replace the userEntries with a new instance of userSolution
    setSelectedInputIndex(0); // Reset the selected input index to the first input
    letterRefs.current[0]?.current?.focus(); // Focus the first input
  };

  const checkForDuplicates = (entries: UserSolution) => {
    const mapping = entries['mapping'];
    const filteredMapping = Object.fromEntries(
      Object.entries(mapping).filter(([, value]) => value !== '*')
    );
    const values = Object.values(filteredMapping);
    const duplicates = values.filter((item, index) => values.indexOf(item) !== index);
    return Object.keys(filteredMapping).filter(key => duplicates.includes(filteredMapping[key]));
  };



  /**
   * Handles the hint button. When clicked, the button checks whether the userEntries for the given letterKey have the correct vowel already, and if not will populate that letter. If it's already correct, then it will move to the next vowel, until it finds a vowel that it can provide a hint for, or gives an error that no more can be shown. Some games will have hints available in which case the hintMapping variable will be defined and not empty. If provided, the hint is a string that indicates which letterKeys have the vowels 'A', 'E', 'I', 'O', and 'U'. The positions in the string are alphabetically ordered (i.e., 'AEIOU'). The hintMapping may appear as 'IACMR' which would mean that the letterKey 'I' has a plaintext mapping of 'A'. 
   *
   */
  const handleHint = () => {
    const currentEntries = userEntries.getMapping();
    const vowels = ['A', 'E', 'I', 'O', 'U'];
    const hintMappingMap: Record<string, string> = {
      'A': hintMapping[0],
      'E': hintMapping[1],
      'I': hintMapping[2],
      'O': hintMapping[3],
      'U': hintMapping[4],
    };
    let hintGiven = false;

    for (const vowel of vowels) {
      if (currentEntries[vowel] !== hintMappingMap[vowel]) {
        console.debug(`Giving user hint for letter ${vowel}`);
        setUserEntries((currentEntries) => currentEntries.updateMapping(hintMappingMap[vowel], vowel));
        hintGiven = true;
        break;
      }
    }

    if (!hintGiven) {
      console.debug("No more hints available.");
      notifications.show({ message: "No more hints available.", color: "red" });
    }

  };

  const close = () => {
    closePuzzleSolvedModal(); // Immediately close the modal

    setTimeout(() => {
      // Replace this with your actual navigation code
      navigate('/load-games');
    }, 1000); // Delay navigation for 5 seconds
  };

  // **************** Letter Generation ****************
  const [sentence, setSentence] = useState(<Sentence cipherWords={cipherWords} letterRefs={letterRefs} letterCounts={letterCounts} selectedLetter={selectedLetter} userEntries={userEntries} focusNextInput={focusNextInput} handleUserInput={handleUserInput} handleKeyEvent={handleKeyEvent} handleFocusEvent={handleFocusEvent} duplicates={[]} />);

  return (
    <>
      <Modal opened={modalOpened} onClose={close} title="Congratulations!" centered>
        <Center>
          <Text size="lg" style={{ marginBottom: '20px' }}>
            You've correctly solved the puzzle!
          </Text>
        </Center>
        <Button fullWidth onClick={close}>
          Close
        </Button>
      </Modal>
      <Modal opened={modalIncorrectOpened} onClose={closeIncorrectModal} title="Try Again!" centered>
        <Center>
          <Text size="lg" style={{ marginBottom: '20px' }}>
            Unfortunately, that's not the right solution. Please try again!"
          </Text>
        </Center>
        <Button fullWidth onClick={closeIncorrectModal}>
          Retry
        </Button>
      </Modal>
      <Card title="Cryptogram">
        <Title order={3}>Your Code to Solve:</Title>
        <Text>{cipherText}</Text>
        {plainTextSolution && <Text c="green" fw={700}>Solved Phrase: {plainTextSolution}</Text>}
      </Card>
      <Space h="md" />
      <Flex
        miw={50}
        gap="xls"
        justify="flex-start"
        align="flex-start"
        direction="row"
        wrap="wrap"
      >
        {/* {wordMap} */}
        {sentence}
      </Flex>
      <Group gap="xs" mt="md">
        <Button type="button" mt="md" onClick={() => handleSubmit()} >Submit</Button>
        <Button type="reset" mt="md" onClick={handleReset}>Reset</Button>
        <Button type="button" mt="md" color='myRedPalette' onClick={handleHint}>Hint</Button>
      </Group>
    </>
  );
}


function getLetterCounts(text: string) {
  const letterCounts: { [letter: string]: number } = {};
  for (const letter of text) {
    if (letter !== ' ') {
      letterCounts[letter] = (letterCounts[letter] || 0) + 1;
    }
  }
  return letterCounts;
}

async function checkSolution(solutionHash: string, userEntries: string | Record<string,string>): Promise<boolean> {
  console.log('User Entries:', userEntries);

  const hash = await computeMapHash(userEntries);
  console.log('Hash:', hash);
  console.log('Solution hash:', solutionHash);
  console.log('Solution correct:', hash === solutionHash);

  return hash === solutionHash;
}
