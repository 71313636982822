
import Letter from './Letter';
import classes from './../Theme.module.css';
import SpecialChar from './SpecialChar';
import { Group } from '@mantine/core';
import { LetterRef, LetterCounts } from '../types/gameTypes';
import { UserSolution } from '../utils/cipher';

interface CipherWordProps {
  word: string;
  wordIndex: number;
  letterRefs: React.RefObject<LetterRef[]>;
  letterCounts: LetterCounts;
  selectedLetter: string;
  userEntries: UserSolution;
  focusNextInput: (index: number, step: number, direction: number) => void;
  handleUserInput: (letter: string, enteredLetter: string) => void;
  handleKeyEvent: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleFocusEvent: (index: number, letter: string) => void;
  startAbsoluteIndex: number; // Add this line
  duplicates: string[];
}

export const Word: React.FC<CipherWordProps> = ({
  word,
  wordIndex,
  startAbsoluteIndex,
  letterRefs: letterRefs,
  letterCounts,
  selectedLetter,
  userEntries,
  focusNextInput,
  handleUserInput,
  handleKeyEvent,
  handleFocusEvent,
  duplicates,
}) => {
  let absoluteIndex = 0; // This needs to be adjusted based on how it's used outside this component

  const letters = word.split('').map((letter) => {
    const currentAbsoluteIndex = startAbsoluteIndex + absoluteIndex;

    let isDuplicate = false;
    if (duplicates && duplicates.length > 0) {
      if (duplicates.includes(letter)) {
        isDuplicate = true;
      }
    }

    // const isDuplicate = duplicates.includes(letter);

    if (letter.match(/[a-zA-Z]/)) {
      const letterComponent = (
        <Letter
          ref={letterRefs.current && letterRefs.current[currentAbsoluteIndex]}
          key={currentAbsoluteIndex}
          letterKey={letter}
          occurrence={letterCounts[letter]}
          focusNextInput={() => focusNextInput(currentAbsoluteIndex, 1, 1)}
          focusPreviousInput={() => focusNextInput(currentAbsoluteIndex, 1, -1)}
          selectedLetter={selectedLetter}
          userEnteredLetter={userEntries.getPlainMapping()[letter] || ''}
          onUserInput={(enteredLetter) => handleUserInput(letter, enteredLetter)}
          onKeyEvent={(event) => handleKeyEvent(event)}
          onFocusEvent={() => handleFocusEvent(currentAbsoluteIndex, letter)}
          error={isDuplicate}
        />
      );
      absoluteIndex++;
      return letterComponent;
    } else if (letter !== ' ') {
      absoluteIndex++;

      const nonPlayableLetterComponent = <SpecialChar
      ref={letterRefs.current && letterRefs.current[currentAbsoluteIndex]}
      letterKey={letter}
      key={currentAbsoluteIndex}
      />;
      return nonPlayableLetterComponent;
    }
  });

  return (
    <Group gap="3px" wrap='nowrap' className={classes.group} key={wordIndex}>{letters}</Group>
  );
};

export default Word;