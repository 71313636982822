
import { Group } from '@mantine/core';
import { LetterRef, LetterCounts } from '../types/gameTypes';
import { UserSolution } from '../utils/cipher';
import Word from './Word';

interface sentenceProps {
    cipherWords: string[];
    letterRefs: React.RefObject<LetterRef[]>;
    letterCounts: LetterCounts;
    selectedLetter: string;
    userEntries: UserSolution;
    focusNextInput: (index: number, step: number, direction: number) => void;
    handleUserInput: (letter: string, enteredLetter: string) => void;
    handleKeyEvent: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleFocusEvent: (index: number, letter: string) => void;
    duplicates: string[];
}


export const Sentence: React.FC<sentenceProps> = ({
    cipherWords,
    letterRefs: letterRefs,
    letterCounts,
    selectedLetter,
    userEntries,
    focusNextInput,
    handleUserInput,
    handleKeyEvent,
    handleFocusEvent,
    duplicates
}) => {
    let absoluteIndex = 0; // This needs to be adjusted based on how it's used outside this component
    const words = cipherWords.map((word, wordIndex) => {
        const currentWord = (
          <Word
          key={wordIndex}
          word={word}
          wordIndex={wordIndex}
          startAbsoluteIndex={absoluteIndex}
          letterRefs={letterRefs}
          letterCounts={letterCounts}
          selectedLetter={selectedLetter}
          userEntries={userEntries}
          focusNextInput={focusNextInput}
          handleUserInput={handleUserInput}
          handleKeyEvent={handleKeyEvent}
          handleFocusEvent={handleFocusEvent}
          duplicates={duplicates}
        />
        );
        absoluteIndex += word.length; // Update absoluteIndex for the next word
        return currentWord;
      })
    return <Group>{words}</Group>;
    }

export default Sentence;