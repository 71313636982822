import { FC, useState, useEffect } from 'react';
import { Group, Title } from '@mantine/core'; // Adjust imports as necessary
import classes from "./../Theme.module.css";

// Define a type for the props
interface StopwatchProps {
  startDateTime?: number; // Timestamp of the user's first move
  endDateTime?: number;
  // onEmitUpdatedCount: (count: number) => void; // TODO: Remove this line if not needed
}

export const TotalElapsedCounter: FC<StopwatchProps> = ({ startDateTime = 0 , endDateTime = 0}) => {
  const [time, setTime] = useState("00:00:00");
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (endDateTime > 0) { // Timer stops if endDateTime is set
      showTimer(endDateTime - startDateTime);
      setIsComplete(true);
    } else if (startDateTime > 0) { // Timer starts only if startDateTime is set
      intervalId = setInterval(() => {
        const elapsed = Date.now() - startDateTime;
        showTimer(elapsed);
      }, 1000); // Update every second
    }

    return () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    };
  }, [startDateTime, endDateTime]);
  

  const showTimer = (ms: number) => {
    const second = Math.floor((ms / 1000) % 60).toString().padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60) % 60).toString().padStart(2, "0");
    const hour = Math.floor((ms / 1000 / 60 / 60) % 24).toString().padStart(2, "0");
    const day = Math.floor(ms / 1000 / 60 / 60 / 24);
    let formattedTime = `${hour}:${minute}:${second}`;
    if (day > 0) {
      formattedTime = `${day.toString()} days ${formattedTime}`;
    }
    setTime(formattedTime);
  };

  return (
    <Group className={classes.Stopwatch} justify="center">
      {isComplete && <Title className={`${classes.Stopwatch} ${classes.display}`} order={2}>This puzzle was completed in {time}.</Title>}
      {!isComplete && <Title className={`${classes.Stopwatch} ${classes.display}`} order={2}>This puzzle started {time} ago.</Title>}
    </Group>
      );
};

export default TotalElapsedCounter;