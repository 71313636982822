import { TextInput } from '@mantine/core';
import classes from './../Theme.module.css';
import { forwardRef, PropsWithoutRef, RefAttributes, ForwardRefExoticComponent} from 'react';

interface LetterProps {
    letterKey: string;
}
export const SpecialChar: ForwardRefExoticComponent<
    PropsWithoutRef<LetterProps> & RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, LetterProps>(
    ({ letterKey }, ref) => {
  
        return (
            <TextInput
            ref={ref}
            classNames={{
              input: `${classes.specialChar}`,
              label: `${classes.label} ${classes.specialChar}`,
              description: `${classes.description} ${classes.specialChar}`,
          }}
            size='xs'
            radius="md"
            label=" "
            description=" "
            maxLength={1}
            inputWrapperOrder={['input', 'label', 'description', 'error']}
            value={letterKey}
            readOnly
          />
        );
    }
);

export default SpecialChar;