import { TextInput } from '@mantine/core';
// import './Letter.css';
// import classes from '../Theme.module.css';
import classes from './../Theme.module.css';
import { useState, forwardRef, PropsWithoutRef, RefAttributes, ForwardRefExoticComponent, ChangeEvent, useEffect } from 'react';

interface LetterProps {
    letterKey: string;
    occurrence: number;
    focusNextInput: () => void;
    focusPreviousInput: () => void;
    selectedLetter: string;
    userEnteredLetter: string;
    onUserInput: (letter: string) => void;
    onKeyEvent: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocusEvent: (event: React.FocusEvent<HTMLInputElement>) => void;
    error: boolean;
}
export const Letter: ForwardRefExoticComponent<
    PropsWithoutRef<LetterProps> & RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, LetterProps>(
    ({ letterKey, occurrence, focusNextInput, focusPreviousInput, selectedLetter, userEnteredLetter, onUserInput, onFocusEvent, error }, ref) => {
        const [value, setValue] = useState<string>('');

        // TODO: Add prop from parent to determine if the letter is duplicated with other letters.
        

        // Update value state when userEnteredLetter changes
        useEffect(() => {
            setValue(userEnteredLetter);
        }, [userEnteredLetter]);

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;
            const oldValue = value;
            // Check if the entered value is a non-alphabetic character
            if (inputValue === '') {
                setValue('');
                onUserInput(''); // This propagates the change up to the parent component
            }
            else if (!/^[a-zA-Z]+$/.test(inputValue)) {
                // Remove non-alphabetic characters
                const newValue = inputValue.replace(/[^a-zA-Z]/g, '');
                setValue(newValue);
                event.preventDefault(); // Prevent moving to the next input box
            } else if (inputValue.toUpperCase() === letterKey) {
                setValue(oldValue);
                event.preventDefault(); // Prevent moving to the next input box
            } else {
                const newValue = inputValue.toUpperCase();
                // const oldValue = value;
                setValue(newValue);
                onUserInput(newValue); // This propagates the change up to the parent component
                if (oldValue !== newValue && ref) {
                    focusNextInput();
                } else {
                    event.preventDefault(); // Prevent moving to the next input box
                }
            }
        };

        const handleKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
            // Handle keydown event logic here
            console.debug(`Keydown detected on input with key '${event.key}'`);
            switch ((event as React.KeyboardEvent<HTMLInputElement>).key) {
                case 'Backspace':
                    // Your existing logic for handling backspace
                    console.log('Backspace was pressed');
                    if (!event.currentTarget.value) {
                        // Assuming focusPreviousInput is correctly implemented to focus the previous input
                        focusPreviousInput();
                        //  NOTE: Optionally, uncomment the following line to change the beahiour of the backspace key from clearing the previous input to focusing the previous input. I.e., uncommenting the following line will make the backspace equivalent to the left arrow key.
                        // event.preventDefault(); // Prevent the default backspace action if necessary
                    }
                    break;
                case 'ArrowLeft':
                    // Logic to focus the previous input
                    focusPreviousInput();
                    event.preventDefault(); // Prevent the default arrow action to ensure custom behavior
                    break;
                case 'ArrowRight':
                    // Logic to focus the next input
                    focusNextInput();
                    event.preventDefault(); // Prevent the default arrow action to ensure custom behavior
                    break;
                default:
                    // Check if the key pressed matches the current value of the input
                    if (event.key.toUpperCase() === event.currentTarget.value.toUpperCase()) {
                        focusNextInput();
                        event.preventDefault(); // Prevent the default action to ensure custom behavior
                    }
                    break;
            }
        };

        return (
            <TextInput
                ref={ref}
                // className={`letterfield ${letterKey === selectedLetter ? 'highlight' : ''}`}
                classNames={{
                    input: `${classes.input} ${letterKey === selectedLetter ? classes.highlight : ''}`,
                    error: classes.error,
                    label: classes.label,
                    description: classes.description,
                }}
                size='xs'
                radius="md"
                label={letterKey}
                description={`${occurrence}`}
                maxLength={1}
                inputWrapperOrder={['input', 'label', 'description', 'error']}
                value={value}
                onFocus={onFocusEvent}
                onInput={handleChange}
                onKeyDown={handleKeyEvent}
                error={error}
                onContextMenu={(event) => event.preventDefault()}
                autoComplete='off'
                autoCorrect='off'
                autoSave='off'
                spellCheck='false'
            />
        );
    }
);

export default Letter;